import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { Spinner } from "react-bootstrap";
import CallIcon from "../components/call-icon";
import Layout from "../components/layout";
import ReviewCard from "../components/review-card";
import ReviewCardCt from "../components/review-card-ct";
import AreaMap from "../components/areaMap";
import moment from "moment";
import { checkPropertiesForNull } from "../utils/object";
import { SafeHtmlParser } from "../components/safe-html-parser";

const Reviews = () => {
	const [reviewsTotal, setReviewsTotal] = useState([]);

	const data = useStaticQuery(
		graphql`
			{
				site {
					siteMetadata {
						siteUrl
					}
				}
				pageData: wpPage(slug: { eq: "our-locksmith-reviews" }) {
					seoFieldGroups {
						localBusinessSchema
						metaDescription
						metaTitle
						openGraphDescription
						openGraphTitle
						productSchema
						image {
							altText
							publicUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
									original {
										width
										height
									}
								}
							}
						}
					}
					reviewPageFields {
						reviewTitleSection {
							heading
							description
						}
						reviewGoogleMapSection {
							heading
							googleMap
						}
					}
				}
				pageDataHome: wpPage(slug: { eq: "home" }) {
					reviewCountFields {
						googleReviewCount
					}
				}
				reviews {
					reviewCount2
					reviews {
						reviewBody
						datePublished
						author
						source
					}
					reviewsCt {
						body
						date
					}
				}
			}
		`
	);

	const {
		pageData: { seoFieldGroups, reviewPageFields },
		site,
		reviews,
		pageDataHome,
	} = data;

	const { reviewTitleSection, reviewGoogleMapSection } = reviewPageFields;

	useEffect(() => {
		const totalReviews = [];

		const handleReviewsFirst = () => {
			const reviewData = reviews.reviews;
			totalReviews.push(
				...reviewData.map((review) => ({
					reviewBody: review.reviewBody,
					datePublished: new Date(review.datePublished),
					author: review.author,
					source: review.source,
				}))
			);
		};

		const handleReviewsSecond = () => {
			const reviewDataCt = reviews.reviewsCt;
			totalReviews.push(
				...reviewDataCt.map((review) => ({
					reviewBody: review.body,
					datePublished: new Date(moment(review.date).format("YYYY-MM-DD")),
					author: null,
					source: null,
				}))
			);
		};

		handleReviewsFirst();
		handleReviewsSecond();

		setReviewsTotal(
			totalReviews.sort((a, b) => b.datePublished - a.datePublished)
		);
	}, [reviews]);

	const siteUrl = site.siteMetadata.siteUrl;
	const reviewCountUpdated =
		reviews.reviewCount2 + pageDataHome.reviewCountFields.googleReviewCount;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Seymour Locksmiths",
				item: "https://www.seymour-locksmiths.co.uk",
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Our Locksmith Reviews",
				item: "https://www.seymour-locksmiths.co.uk/our-locksmith-reviews",
			},
		],
	};

	return (
		<Layout>
			<CallIcon />
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFieldGroups?.metaTitle}
				description={seoFieldGroups?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/our-locksmith-reviews`,
					title: `${seoFieldGroups?.openGraphTitle}`,
					description: `${seoFieldGroups?.openGraphDescription}`,
					images: [
						{
							url: `${seoFieldGroups?.image?.localFile.publicUrl}`,
							width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
							height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
							alt: `${seoFieldGroups?.image?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@jefflocksmith",
					site: "@jefflocksmith",
					cardType: "summary",
				}}
			/>

			<Container className="mt-24 mb-14 py-5" as="section">
				<Row className="justify-content-center">
					<Col xs={11}>
						<Row className="mb-20">
							<Col className="d-flex justify-content-center" xs={12}>
								<h1 className="text-3xl mb-3 fw-700 lato text-center">
									{reviewTitleSection?.heading ?? "Our Customer Reviews"}
								</h1>
							</Col>
							<Col xs={12}>
								<p className="text-center" id="no-reviews">
									<em>Rated 5 / 5 across {reviewCountUpdated} Reviews</em>
								</p>
							</Col>
							<Col className="d-flex justify-content-center" xs={12}>
								<span className="sl-underline d-block" />
							</Col>

							<Col className="pt-3 text-center">
								{reviewTitleSection?.description && (
									<SafeHtmlParser
										htmlContent={reviewTitleSection?.description}
									/>
								)}
							</Col>
						</Row>
						<Row className="text-center"></Row>

						<Row className="justify-content-center">
							<Spinner
								id="loading-spinner"
								variant="primary"
								animation="border"
								style={{ display: reviewsTotal.length ? "none" : "block" }}
							></Spinner>
							<Row style={{ maxWidth: "100%" }}>
								{reviewsTotal.map((review) => (
									<Col lg={4} md={6} xs={12} key={review.datePublished}>
										{review.source !== null && <ReviewCard review={review} />}
										{review.source === null && <ReviewCardCt review={review} />}
									</Col>
								))}
							</Row>
						</Row>
					</Col>
				</Row>
			</Container>
			{reviewGoogleMapSection &&
				!checkPropertiesForNull(reviewGoogleMapSection, ["heading"]) && (
					<AreaMap
						heading={reviewGoogleMapSection?.heading}
						map={reviewGoogleMapSection?.googleMap}
					/>
				)}
		</Layout>
	);
};

export default Reviews;
